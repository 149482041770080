import React from "react";
import PropTypes from "prop-types";
import "./About.css";
import {
  SettingOutlined,
  AudioOutlined,
  FileMarkdownOutlined,
  UploadOutlined,
  DownOutlined,
  UpOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Row, Col, Icon, Space, Switch, Input, Divider, Card,Result,Button } from "antd";
const { Search } = Input;
function About() {
  const { Meta } = Card;

  return (
    <>
      <div className="header1">
        {" "}
        <InfoCircleOutlined className="icons" />
        <Divider type="vertical" />
        About
      </div>
      <br></br>
      <Result
    status="500"
    title="About"
    subTitle="Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong.Sorry, something went wrong."
   
  />
    </>
  );
}

export default About;
