import { EyeOutlined } from "@ant-design/icons";
import React from "react";
import { createUseStyles } from "react-jss";
import logo from "../../logod.png";
import {Space} from 'antd'
const useStyles = createUseStyles({
  bodyq: {
    display: "flex",

    width: "100vw",
    color: "white",
    backgroundImage: "linear-gradient( 355deg, #20A2FF, #034EA2)",
    paddingLeft: "15px",
    margin: "0px",
    height: "25px",
  },
  img: {
    width: "100px",

    height: "100px",
  },
  logoname: {
    fontSize: "1rem",
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    margin: "0px",
    verticalAlign: "middle",
    justifyContent: "end",
    fontWeight: 800,
  },
  ul: {
    fontSize: "1rem",
    textDecoration: "none",

    fontWeight: 800,
  },
  li: {
    fontWeight: 800,
  },
  menubar: {
    listStyleType: "none",
    display: "flex",
    flexDirection: "row",
paddingRight:"35px",
   width:"100%",
    color: "white",
  },
  menubar1: {
    position: "absolute",
    listStyleType: "none",
    display: "flex",
    flexDirection: "row",
    float: "right",
   
    right: "0px",
    color: "black",
  },
});
function VikasHeaderComponent() {
  const classes = useStyles();
  return (
    <div className={classes.bodyq}>
      <EyeOutlined className={classes.logoname} /> LetsDoIT
      <div className={classes.logoname}></div>{" "}
      <div className={classes.menubar1}>
        <ul className={classes.menubar}><Space>  <li className={classes.li}>Add</li>
        <li className={classes.li}>Edit</li>
        <li className={classes.li}>Delete</li></Space>
        
        </ul>
      </div>
    </div>
  );
}

export default VikasHeaderComponent;
