import React from "react";
import PropTypes from "prop-types";
import "./Backupandrestore.css";
import { SettingOutlined, AudioOutlined, FileMarkdownOutlined, UploadOutlined, DownOutlined, UpOutlined, DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Icon, Space, Switch, Input, Divider, Card ,Image} from "antd";
import logo from "../logohor.png";
const { Search } = Input;
function Backupandrestore(props) {
  const {} = props;

  return (
    <>
      <div className="headerAppbar">
        {" "}
        <Image src={logo} width="125px"  height="40px"/>
        <Divider type="vertical" />
        Backup and Restore
      </div>
      <br></br>
      <div
        style={{
          padding: "45px",
          placeContent: "center",
          verticalAlign: "center",
        }}
      >
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Card style={{ width: "100%", height: "15rem" }} title="Backup" hoverable>
              <p>Please Select backup Folder</p>
              <Search
                placeholder="folder path"
                enterButton="Search"
                size="large"
                suffix={
                  <DownloadOutlined
                    style={{
                      fontSize: 16,
                      color: "#1890ff",
                    }}
                  />
                }
              />
            </Card>
          </Col>

          <Divider plain />
          <Col span={24}>
            <Card style={{ width: "100%", height: "15rem" }} title="Restore" hoverable>
              <p>Please Select restore File / Folder</p>
              <Search
                placeholder="file/folder path"
                enterButton="Search"
                size="large"
                suffix={
                  <UploadOutlined
                    style={{
                      fontSize: 16,
                      color: "#1890ff",
                    }}
                  />
                }
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

Backupandrestore.propTypes = {};

export default Backupandrestore;
