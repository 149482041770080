import React, { useState } from "react";
import { CameraOutlined, DollarOutlined, HomeOutlined, LikeOutlined, RestOutlined, ThunderboltOutlined, UserOutlined } from "@ant-design/icons";

import { createUseStyles } from "react-jss";
import { Card, Row, Col, Statistic } from "antd";
const useStyles = createUseStyles({
  body: {
    width: "100vw",
    display: "grid",
    background: "linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF )",

    height: "100vh",
    padding: "18px",
  },
  card: {
    borderRadius: "16px",
    marginRight: "24px",
    boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
    height: "300px",
    margin: "10px",
    backgroundImage: "linear-gradient( 135deg, #3C8CE7 10%, #00EAFF 100%)"
  }, card2: {
    borderRadius: "16px",
    marginRight: "24px",
    boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
    height: "300px",
    margin: "10px",
    backgroundImage: "linear-gradient( 135deg, #FEB692 10%, #EA5455 100%)"
  }, card3: {
    borderRadius: "16px",
    marginRight: "24px",
    boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
    height: "300px",
    margin: "10px",
    backgroundImage: "linear-gradient( 135deg, #CE9FFC 10%, #7367F0 100%)"
  }, card4: {
    borderRadius: "16px",
    marginRight: "24px",
    boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
    height: "300px",
    margin: "10px",
    backgroundImage: "linear-gradient( 135deg, #90F7EC 10%, #32CCBC 100%)"
  }, card5: {
    borderRadius: "16px",
    marginRight: "24px",
    boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
    height: "300px",
    margin: "10px",
    backgroundImage: "linear-gradient( 135deg, #F761A1 10%, #8C1BAB 100%)"
  }, card6: {
    borderRadius: "16px",
    marginRight: "24px",
    boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
    height: "300px",
    margin: "10px",
    backgroundImage: "linear-gradient( 135deg, #69FF97 10%, #00E4FF 100%)"
  },
});

function Vikastestdashboard() {
  const classes = useStyles();

  return (
    <>
      {" "}
      <div className={classes.body}>
        <Row gutter={[5, 5]}>
          <Col lg={6} sm={24} xs={24}>
            <Card bordered className={classes.card}>
            <div style={{position :"relative",width:"200px", height:"150px",left:"-45px",top:"15px",backgroundImage: "linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF )", borderRadius: "16px",
            marginRight: "24px",
            boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
            height: "200px",
            margin: "10px",}}><DollarOutlined style={{fontSize:"150px",color:"white"}}/></div>
              <Statistic
                title="Feedback"
                value={1128}
                prefix={<LikeOutlined />}
              />
            </Card>
          </Col>
          <Col lg={6} sm={24} xs={24}>
            <Card bordered className={classes.card2}>
            <div style={{position :"relative",width:"200px", height:"150px",left:"-45px",top:"15px",backgroundImage: "linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF )", borderRadius: "16px",
            marginRight: "24px",
            boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
            height: "200px",
            margin: "10px",}}><HomeOutlined style={{fontSize:"150px",color:"white"}}/></div>  <Statistic title="Unmerged" value={932} suffix="/ 100" />
            </Card>
          </Col>{" "}
          <Col lg={6} sm={24} xs={24}>
            <Card bordered className={classes.card3}>
            <div style={{position :"relative",width:"200px", height:"150px",left:"-45px",top:"15px",backgroundImage: "linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF )", borderRadius: "16px",
            marginRight: "24px",
            boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
            height: "200px",
            margin: "10px",}}><CameraOutlined style={{fontSize:"150px",color:"white"}}/></div>
              <Statistic
                title="Feedback"
                value={1128}
                prefix={<LikeOutlined />}
              />
            </Card>
          </Col>
          <Col lg={6} sm={24} xs={24}>
            <Card bordered className={classes.card4}>
            <div style={{position :"relative",width:"200px", height:"150px",left:"-45px",top:"15px",backgroundImage: "linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF )", borderRadius: "16px",
            marginRight: "24px",
            boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
            height: "200px",
            margin: "10px",}}><RestOutlined style={{fontSize:"150px",color:"white"}}/></div>
              <Statistic
                title="Feedback"
                value={1128}
                prefix={<LikeOutlined />}
              />
            </Card>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Card bordered className={classes.card5}>
            <div style={{position :"relative",width:"200px", height:"150px",left:"-45px",top:"15px",backgroundImage: "linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF )", borderRadius: "16px",
            marginRight: "24px",
            boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
            height: "200px",
            margin: "10px",}}><UserOutlined style={{fontSize:"150px",color:"white"}}/></div>
           
              <Statistic title="Unmerged" value={932} suffix="/ 100" />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Vikastestdashboard;
