import React, { useStyle } from "react";
import "./Requirement.css";
import { Col, Divider, Form, Row, Segmented, Space } from "antd";
import {
  AppstoreOutlined,
  BarcodeOutlined,
  BarsOutlined,
  DeleteFilled,
  DeleteOutlined,
  DeleteTwoTone,
  FileAddFilled,
  FileAddOutlined,
  FileAddTwoTone,
  FilterFilled,
  FilterOutlined,
  FilterTwoTone,
} from "@ant-design/icons";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Radio, Input, Tag, Typography } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
const { Search } = Input;
const { TextArea } = Input;
const { Text } = Typography;
// Apply style to all form

function RequirementDrawer() {
  return (
    <Form layout="horizontal" wrapperCol={24}>
      <div className="requirementDrawer">
      
        <div>
          <Row gutter={[3, 3]}>
            <Col span={24}>
              {" "}
              <Form.Item label="Description">
                <Input
                  size="small"
                  disabled
                  type={"text"}
                  value="Bugzilla Contest management"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Description">
                {" "}
                <Input type={"text"} size="small" />{" "}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Description">
                {" "}
                <Input type={"text"} size="small" />{" "}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Description" style={{ margin: "0px" }}>
            {" "}
            <TextArea
              size="small"
              rows={4}
              addonBefore="Description"
              type={"text"}
              value="Bugzilla Contest management"
              multiple
            />
          </Form.Item>
          <Divider orientation="left" style={{ margin: "0px" }}>
            Tags
          </Divider>
          <Form.Item>
            {" "}
            <div>
              <Tag color="success">success</Tag>
              <Tag color="processing">processing</Tag>
              <Tag color="error">error</Tag>
              <Tag color="warning">warning</Tag>
              <Tag color="default">default</Tag>
            </div>
          </Form.Item>{" "}
          <Divider orientation="left" style={{ margin: "0px" }}>
            Environments
          </Divider>
          <Form.Item>
            {" "}
            <div>
              <Tag color="success">success</Tag>
              <Tag color="processing">processing</Tag>
              <Tag color="error">error</Tag>
              <Tag color="warning">warning</Tag>
              <Tag color="default">default</Tag>
            </div>
          </Form.Item>
          <Form.Item label="Test Plans">
            {" "}
            <Input
              addonBefore=""
              type={"text"}
              value="Bugzilla Contest management"
            />
          </Form.Item>
          <Form.Item label="Created Data">
            {" "}
            <div style={{ fontSize: "10px", paddingLeft: "15px" }}>
              "Bugzilla Contest management"
            </div>
          </Form.Item>
          <Form.Item label="Update Data">
            {" "}
            <Text type="secondary">"Bugzilla Contest management"</Text>
          </Form.Item>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          padding: "20px",
          placeContent: "end",
          bottom: "20px",
          alignItems: "end",
          position: "sticky",
        }}
      >
        <Space>
          <Button icon={<FilterOutlined />}></Button>
          <Button icon={<FileAddOutlined />}>Add</Button>
          <Button icon={<DeleteOutlined />}>Delete</Button>
        </Space>
      </div>
    </Form>
  );
}

export default RequirementDrawer;
