import React from "react";
import { Flex, Container, Text, Link, Box } from "@chakra-ui/react";
import {

  SimpleGrid,
  Stack,
  HStack,
  Heading,
 
 List,ListIcon,ListItem,
  Icon,
  Image,

  Skeleton,

  useColorModeValue,
  IconButton,
  chakra,

Menu,MenuButton,MenuItem,MenuList,MenuGroup,MenuDivider,
  VisuallyHidden,

  Button,
  useDisclosure,
  VStack,
  
  CloseButton,
} from "@chakra-ui/react";
import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,FaArrowRight
} from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import logo from '../../logod.png';
import { auth, logInWithEmailAndPassword, logout } from "../../firebase";
import Colormodeswitcher from "../../Colormodeswitcher";
function Settings(props) {
  const {} = props;
  const bg = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();
  return (
    <>  
     {/*Navigation*/}
    <chakra.header
    bg={bg}
    w="full"
    px={{ base: 2, sm: 4 }}
    py={4}
    shadow="md"
 
  >
    <Flex alignItems="center" justifyContent="space-between" mx="auto">
      <Flex>
        <chakra.a
          href="/"
          title="Choc Home Page"
          display="flex"
          alignItems="center"
        >
         
          <VisuallyHidden>Choc</VisuallyHidden>
        </chakra.a><FaTwitter/> 
        <chakra.h1 fontSize="xl" fontWeight="medium" ml="2">
        LetzdoIT
        </chakra.h1>
      </Flex>
      <HStack display="flex" alignItems="center" spacing={1}>
        <HStack
          spacing={1}
          mr={1}
          color="brand.500"
          display={{ base: "none", md: "inline-flex" }}
        >
          <Button variant="ghost">Features</Button>
          <Button variant="ghost">Pricing</Button>
          <Button variant="ghost">Community</Button>
          <Button variant="ghost">About us</Button>
          <Button variant="ghost">Sign in</Button>
        </HStack>
       <Menu>
        <MenuButton as={Button} colorScheme='pink'>
          Wow
        </MenuButton>
        <MenuList>
          <MenuGroup title='Profile'>
            <MenuItem>My Account</MenuItem>
            <MenuItem>Payments </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title='Help'>
            <MenuItem>Docs</MenuItem>
            <MenuItem>FAQ</MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
        <Colormodeswitcher/>
        <Box display={{ base: "inline-flex", md: "none" }}>
          <IconButton
            display={{ base: "flex", md: "none" }}
            aria-label="Open menu"
            fontSize="20px"
            color={useColorModeValue("gray.800", "inherit")}
            variant="ghost"
            icon={<AiOutlineMenu />}
            onClick={mobileNav.onOpen}
          />

          <VStack
            pos="absolute"
            top={0}
            left={0}
            right={0}
            display={mobileNav.isOpen ? "flex" : "none"}
            flexDirection="column"
            p={2}
            pb={4}
            m={2}
            bg={bg}
            spacing={3}
            rounded="sm"
            shadow="sm"
          >
            <CloseButton
              aria-label="Close menu"
              onClick={mobileNav.onClose}
            />

            <Button w="full" variant="ghost">
              Features
            </Button>
            <Button w="full" variant="ghost">
              Pricing
            </Button>
            <Button w="full" variant="ghost">
              Community
            </Button>
            <Button w="full" variant="ghost">
              About Us
            </Button>
            <Button w="full" variant="ghost">
              Sign in
            </Button>
          </VStack>
        </Box>
      </HStack>
    </Flex>
  </chakra.header>
      {/*Hero*/}
      <Box maxW="7xl" mx="auto" px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '12' }}>
    <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
      <Box
        width={{ lg: 'sm' }}
        transform={{ base: 'translateY(-50%)', lg: 'none' }}
        bg={{ base: useColorModeValue('red.50', 'gray.700'), lg: 'transparent' }}
        mx={{ base: '6', md: '8', lg: '0' }}
        px={{ base: '6', md: '8', lg: '0' }}
        py={{ base: '6', md: '8', lg: '12' }}
      >
        <Stack spacing={{ base: '8', lg: '10' }}>
          <Stack spacing={{ base: '2', lg: '4' }}>
            <Heading size="xl" color={useColorModeValue('red.500', 'red.300')}>
              Misguided by tools
            </Heading>
            <Text>Project management is the use of specific knowledge, skills, tools and techniques to deliver something of value to people. The development of software for an improved business process, the construction of a building, the relief effort after a natural disaster, the expansion of sales into a new geographic market—these are all examples of projects.</Text>
            <Heading size="xl" fontWeight="normal">
              make a move
            </Heading>
          </Stack>
          <HStack spacing="3">
            <Link color={useColorModeValue('red.500', 'red.300')} fontWeight="bold" fontSize="lg">
              Discover now
            </Link>
            <Icon color={useColorModeValue('red.500', 'red.300')} as={FaArrowRight} />
          </HStack>
        </Stack>
      </Box>
      <Flex flex="1" overflow="hidden">
        <Image
          src="https://images.unsplash.com/photo-1589156229687-496a31ad1d1f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80"
          alt="Lovely Image"
          fallback={<Skeleton />}
          maxH="450px"
          minW="300px"
          objectFit="cover"
          flex="1"
        />
        <Image
          display={{ base: 'none', sm: 'initial' }}
          src="https://images.unsplash.com/photo-1589156206699-bc21e38c8a7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80"
          alt="Lovely Image"
          fallback={<Skeleton />}
          maxH="450px"
          objectFit="cover"
        />
      </Flex>
    </Stack>
  </Box>
   


     
      {/*Pricing*/}
      <Box
    as="section"
    bg={useColorModeValue('gray.50', 'gray.800')}
    py="14"
    px={{ base: '4', md: '8' }}
  >
    <SimpleGrid
      columns={{ base: 1, lg: 3 }}
      spacing={{ base: '8', lg: '0' }}
      maxW="7xl"
      mx="auto"
      justifyItems="center"
      alignItems="center"
    >

      
     
    <FaArrowRight/> Free Project management tool forever
   
    </SimpleGrid>
  </Box>
      {/*footer*/}
      <Flex pt="4rem" pb="4rem" backgroundColor="twitter.900">
    <Container maxW={["sm", "4xl"]}>
      <SimpleGrid
        columns={[1, 3]}
        spacingX={10}
        spacingY={{ base: "2rem", md: 0 }}
      >
        <Stack spacing={2}>
          <Heading as="h3" color="white" size="md" textAlign="center">
            LOCATION
          </Heading>
          <Text textAlign="center" color="white">
            Ahmedabad , India
          </Text>
        </Stack>
        <Stack spacing={2}>
          <Heading color="white" textAlign="center" size="md">
            SOCIAL MEDIA
          </Heading>
          <SimpleGrid columns={4} spacingX={1} spacingY={1}>
            <IconButton
              aria-label="icon"
              icon={<FaTwitter />}
              size="md"
              colorScheme="telegram"
              isRound
              w={12}
              h={12}
            />
            <IconButton
              colorScheme="telegram"
              aria-label="icon"
              icon={<FaFacebookF />}
              size="md"
              isRound
              w={12}
              h={12}
            />
            <IconButton
              colorScheme="telegram"
              aria-label="icon"
              icon={<FaInstagram />}
              size="md"
              isRound
              w={12}
              h={12}
            />
            <IconButton
              colorScheme="telegram"
              aria-label="icon"
              icon={<FaLinkedin />}
              size="md"
              isRound
              w={12}
              h={12}
            />
          </SimpleGrid>
        </Stack>
        <Stack spacing={2}>
          <Heading size="md" textAlign="center" color="white">
            ABOUT THIS
          </Heading>
          <Text color="white" textAlign="center">
           Grow as much as we can   
           
           
          </Text>
        </Stack>
      </SimpleGrid>
    </Container>
  </Flex>
      {/*copyright*/}
    
      <Flex pt={10} pb={10} backgroundColor="telegram.900">
        <Container maxW={["sm", "4xl"]}>
          <Text color="white" textAlign="center">
            Copyright © letzDOIT 2021
          </Text>
        </Container>
      </Flex>
    </>
  );
}

Settings.propTypes = {};

export default Settings;
