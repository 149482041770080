import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Backupandrestore.css";
import {
  SettingOutlined,
  AudioOutlined,
  FileMarkdownOutlined,
  UploadOutlined,
  DownOutlined,
  UpOutlined,
  DownloadOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Icon,
  Space,
  Switch,
  Input,
  Divider,
  Card,
  Button,
  Badge,
  Dropdown,
  Checkbox,
} from "antd";
import { Table, Tag, Drawer } from "antd";
const { Search } = Input;

const data = [
  {
    key: "1",
    name: "John Brown",
    age: true,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "develo"],
  },
  {
    key: "2",
    name: "aJohn Brown",
    age: false,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "coder"],
  },
  {
    key: "3",
    name: "aJohn Brown",
    age: false,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "coder"],
  },
  {
    key: "4",
    name: "Vikas kala",
    age: false,
    address: "New York No. 1 Lake Park",
    tags: ["loser"],
  },
];
function Usermanagement() {
  const [visible, setVisible] = useState(false);
  const [reord, setRecord] = useState("");

  const showDrawer = (record) => {
    setVisible(true);
    setRecord(record);
  };

  const onClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "age",
      key: "age",
      render: (_, { age }) => (
        <>
          <Switch checked={age} size={"small"} />
        </>
      ),
    },
    {
      title: "isAdmin",
      dataIndex: "address",
      key: "address",
      width: "30%",
    },
    {
      title: "Roles",
      key: "tags",
      dataIndex: "tags",
      width: "30%",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";

            if (tag === "loser") {
              color = "volcano";
            }

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            shape="circle"
            icon={<EditOutlined onClick={() => showDrawer(record)} />}
          ></Button>
          <Button shape="circle" icon={<DeleteOutlined />} danger onClick={() => showDrawer(record)} ></Button>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Drawer
        title={reord.name}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <p>
          {" "}
          <Switch checked={reord.age} size={"small"} />
        </p>
        <p>{reord.address}</p> <p>{reord.tags}</p>
      </Drawer>
      <div className="header">
        {" "}
        <UserOutlined className="icons" />
        <Divider type="vertical" />
        User Management
      </div>
      <br></br>
      <div
        style={{
          padding: "10px",
          placeContent: "center",
          verticalAlign: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <Card
              style={{ width: "100%", height: "100%" }}
              title={<Search loading />}
              extra={
                <>
                  {" "}
                  <Space>
                    <Button shape="circle" icon={<DownloadOutlined />}></Button>
                    <Button shape="circle" icon={<DownloadOutlined />}></Button>
                    <Dropdown.Button shape="circle" overlay={"sad"}>
                      Actions
                    </Dropdown.Button>
                  </Space>
                </>
              }
            >
              <Row gutter={[16, 16]} align="middle">
                <Col span={24}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    scroll
                    pagination={{ pageSize: 10, position: ["bottomRight"] }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Usermanagement;
