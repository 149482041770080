
import React, { useState }from "react";
import { createUseStyles } from "react-jss";
import FilterModel from "./FilterModel";
import logo from "../../logod.png";
import DrawerModel from "./DrawerModel";
import TableModel from "./TableModel";
import BreadcrumbCompo from "./BreadcrumbCompo";
import HeaderCom from "./HeaderCom";
import SideNavComp from "./SideNavComp";
import { PageHeader } from "antd";
import { BackwardOutlined } from "@ant-design/icons";
const useStyles = createUseStyles({
  body: {
    width: "100vw",
    display: "flex",
    background: "linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF )",
    overflow:"hidden",
    height:"100vh"
  },
  container: {
    display: "grid",
    padding: "1px",
    width: "100vw",
    grid: "repeat(3, 50px) / auto-flow;",
    gridGap: "0px",
  },
  appbar: {
    backgroundImage: "linear-gradient( 135deg, #3B2667 10%, #BC78EC 100%)",
    
    width: "10px",
    height: "100vh",
  },
  sideorange: {
    height: "300px",
    width: "400px",
    background: "linear-gradient(120deg, #ff8800, #ff3300)",

    position: "absolute",
    right: "0px",
  },
  sideblue: {
    width: "100vw",
    height: "50px",

    right: "0px",
    //    background: "linear-gradient(to bottom, #040404, #000000, #656565)",
    position: "inherit",
  },
  h1: {
    fontSize: "72px",
    background: "-webkit-linear-gradient(#eee, #333)",
  },
  ul: {
    listStyleType: "none",
  },
  li: {
    textDecoration: "none",
    fontStyle: "unset",
  },
});

function Vikastest() {
  const classes = useStyles();
  
  return (
    <>
        <div className={classes.body}>
      
        <div className={classes.container}>
          {" "}
          <div style={{ padding: "0px" }}>
          <HeaderCom/>
       
          </div>
          <div style={{  }}>
            <BreadcrumbCompo/>
          </div><div><TableModel/> <FilterModel/>
          <DrawerModel/>
          </div>
        </div>
      </div>
        </>
  );
}

export default Vikastest;
