import { Divider } from 'antd'
import React from 'react'
import { Link, useNavigate,BrowserRouter } from "react-router-dom";
import About from '../pages/About'
import Backupandrestore from '../pages/Backupandrestore'
import Requirement from '../pages/requirement/Requirement'
import Profile from '../pages/profile/Profile'
import Login from '../pages/Login'
import Settings from '../pages/setting/Settings' 
import Usermanagement from '../pages/Usermanagement'
import Vikastest from '../pages/vikastest/Vikastest'
import TestSteps from '../pages/TestSteps'
import Vikastestdashboard from '../pages/vikasdashboardtest/Vikastestdashboard'
import Testplan from '../pages/setting/testplan/Testplan'
import VikasHeaderComponent from '../pages/vikasheadercompo/VikasHeaderComponent'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "../firebase";
function App() {
  return (<>

    <BrowserRouter>
      <Routes>{/*<Route path="/" element={<Login />} />
      <Route path="/setting" element={<Settings />} />
  */}
        <Route path="/" element={<Settings />} />
       
        
      
        
      </Routes>
    </BrowserRouter>
   

   </>
  )
}

export default App
