import { Drawer } from 'antd';
import React,{useState} from 'react'

import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
   
    cardq: {
      borderRadius: "16px",
     
      boxShadow: "1px 2px 12px 1px rgba(208, 216, 243, 0.4)",
     

    },
  });
function DrawerModel() {
    const classes = useStyles();
    const [v, setV] = useState(true);
    return (
        <Drawer className={classes.cardq} title="Basic Drawer" placement="right" onClose={(e)=>setV(false)} visible={v}>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Drawer>
    )
}

export default DrawerModel
