import { Col, Divider, Form, Row, Segmented, Space } from "antd";
import { Drawer } from "antd";
import React, { useState } from "react";
import {
  AppstoreOutlined,
  BarChartOutlined,
  BarsOutlined,
  DeleteFilled,
  DeleteOutlined,
  DeleteTwoTone,
  EditFilled,
  FileAddFilled,
  FileAddOutlined,
  FileAddTwoTone,
  FilterFilled,
  FilterOutlined,
  FilterTwoTone,
  BarcodeOutlined,
  EyeFilled,
  EyeInvisibleTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";
import { Table, Tag ,Select} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Radio, Input, Modal, Popover } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import RequirementDrawer from "./RequirementDrawer";

function ShowDraer(a) {
  return (
    <Drawer
      title={
        <>
          <BarcodeOutlined /> Project Detail
        </>
      }
      placement={"right"}
      closable={true}
      visible={true}
      keyboard={true}
      width="40vw"
      bodyStyle={{
        flexGrow: 1,
        padding: "0px 0px",
        overflow: "auto",
        fontSize: "12px",
        lineHeight: "1.5715",
        wordWrap: "break-word",
      }}
      style={{ flexGrow: 1, color: "white" }}
    >
      <RequirementDrawer />
    </Drawer>
  );
}
const { Option } = Select;
const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
const { Search } = Input;

function RequirementTable() {
  const [visible, setVisible] = useState(false);
  const [recordpro, setRecordpro] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showDrawer = (rec) => {
    console.log(rec.name);
    setRecordpro(rec);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";

            if (tag === "loser") {
              color = "volcano";
            }

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="low">
          <Button shape="circle" type="text" onClick={() => showDrawer(record)}>
            <EyeTwoTone />
          </Button>
          <Button shape="circle" type="text" style={{ color: "green" }}>
            <EditFilled />
          </Button>
          <Button shape="circle" type="text" danger>
            <DeleteFilled />
          </Button>
        </Space>
      ),
    },
  ];
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  
  return (
    <>
      <br />
      <Row gutter={[15, 15]}>
        <Col span={"auto"}>
          <div style={{ fontSize: "20px", paddingLeft: "15px" }}>
            <Space>
              <BarChartOutlined />
              Project Detail
            </Space>
          </div>
        </Col>
        <Col span={"auto"}>
          {" "}
          <Space>
            {" "}
            <Search
              placeholder="Search requirement by ID,name"
              allowClear
              suffix={<FilterTwoTone />}
              style={{
                width: "350px",
              }}
            />{" "}
           
              <Button icon={<FilterOutlined />}>Filter</Button>
       
            <Button icon={<FileAddOutlined />}>Add New Requirement </Button>
            <Button icon={<DeleteOutlined />}>Delete Selected</Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Table columns={columns} dataSource={data} />
      <Button type="primary" onClick={showDrawer}>
        Open
      </Button>
      <Drawer
        title={
          <>
            <BarcodeOutlined /> Project Detail
          </>
        }
        placement={"right"}
        closable={true}
        onClose={onClose}
        visible={visible}
        keyboard={true}
        width="40vw"
        bodyStyle={{
          flexGrow: 1,
          padding: "0px 0px",
          overflow: "auto",
          fontSize: "12px",
          lineHeight: "1.5715",
          wordWrap: "break-word",
        }}
        style={{ flexGrow: 1 }}
      >
        <div style={{ flexGrow: 1, fontSize: "20px" }}>{recordpro.name}</div>
        <RequirementDrawer />
      </Drawer>
      <Button type="primary" onClick={showModal}>
        Open Modal
      </Button>
      <Modal
        title="Filter"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      ><Row>
      <Col span={24}>
        <Form layout="vertical">
          <Form.Item >
            <Input type={"text"} size="medium"  addonBefore="Name"/>{" "}
          
          </Form.Item>   <Form.Item label="Tag">
            <Input type={"text"} size="medium"   addonBefore="Name"/>{" "}
          
          </Form.Item>   <Form.Item >
          <Select labelinValue={"asdasda"}
          defaultValue="lucy"
          style={{
            width: 120,
          }}
          onChange={handleChange} 
        >
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
          <Option value="disabled" disabled>
            Disabled
          </Option>
          <Option value="Yiminghe">yiminghe</Option>
        </Select>
          
          </Form.Item>
        </Form>
      </Col>
    </Row></Modal>
    </>
  );
}

export default RequirementTable;
